<template>
  <div class="wrapper">
    <div class="header">
      <admin-title :title="$route.meta.name"></admin-title>
      <span>
        <el-button
          @click="$router.back()"
          type="info"
          icon="el-icon-back"
          size="mini"
          circle
        ></el-button>
      </span>
    </div>

    <el-form
      ref="form"
      disabled
      :model="formData"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="规则名称" prop="ruleName">
        <el-input
          style="width: 400px"
          size="small"
          v-model="formData.ruleName"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="规则类型" prop="ruleType">
        <el-select
          @change="handleChangeRuleType"
          style="width: 400px"
          clearable
          v-model="formData.ruleType"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in dict_ruleType"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="规则策略"
        prop="strategyConfig"
        v-if="formData.ruleType"
      >
        <div
          @mouseleave="currentIndex = -1"
          class="content"
          v-if="formData.strategyConfig.length > 0"
        >
          <div
            :class="['row', currentIndex == i ? 'active' : '']"
            @mouseenter="changeIndex(i)"
            v-for="(item, i) in formData.strategyConfig"
            :key="i"
          >
            <div class="col">
              <span> {{ item.strategy.strategyName }}</span>
            </div>
            <!-- 输入值 -->
            <div class="col" v-if="item.strategy.elType == 1">
              <span>
                <el-select
                  style="width: 120px; margin-right: 20px"
                  v-model="item.operator"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in dict_operator"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span>
              <span class="eq" v-if="item.operator == 'between'">
                <el-input
                  v-model="item.hits[0]"
                  style="width: 120px"
                  size="small"
                  placeholder="请输入"
                ></el-input>
                <span style="margin: 0 10px">到</span>
                <el-input
                  v-model="item.hits[1]"
                  style="width: 120px"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </span>
              <span class="eq" v-else>
                <el-input
                  v-model="item.hits[0]"
                  style="width: 120px"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </span>
            </div>
            <!-- 枚举值 -->
            <div class="col" v-if="item.strategy.elType == 2">
              <el-checkbox-group
                style="margin-top: 5px"
                v-model="formData.strategyConfig[i].hits"
              >
                <el-checkbox
                  v-for="item in item.strategy.elData"
                  :label="item.value"
                  :key="item.value"
                  >{{ item.label }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <!-- 用户黑名单 -->
            <div class="col black" v-if="item.strategy.elType == 3">---</div>
            <div class="col">
              <!-- <el-button type="danger" size="mini" @click="handleRemove(i)">删除</el-button> -->
            </div>
          </div>
        </div>
        <div class="empty" v-else>
          <el-empty description="暂无策略"></el-empty>
        </div>
        <!-- 添加按钮 -->
        <!-- <div class="add">
          <el-button @click="showList" style="width:200px" plain type="primary" size="small">添加</el-button>
        </div> -->
      </el-form-item>
      <!-- <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item> -->
    </el-form>
    <!-- 策略列表 -->
    <el-dialog title="规则策略" :visible.sync="dialogVisible" width="900px">
      <el-tabs v-model="searchData.strategyType">
        <el-tab-pane label="系统" name="1"></el-tab-pane>
        <el-tab-pane
          v-if="formData.ruleType != 1"
          label="百融"
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          v-if="formData.ruleType != 1"
          label="优分"
          name="3"
        ></el-tab-pane>
      </el-tabs>
      <!-- <tp-table :isNeedSerialNumber="false" :showPagination="false" :tableData="list" :columns="columns" /> -->
      <el-table
        :data="list"
        style="width: 100%; max-height: 300px"
        :header-cell-style="{ color: '#1a1e25', background: '#f5f5f5' }"
      >
        <el-table-column prop="prop" label="" width="100">
          <template slot-scope="{ row }">
            <div :title="findCode(row['strategyCode']) ? '该策略已被选！' : ''">
              <el-radio
                :disabled="findCode(row['strategyCode'])"
                v-model="strategyId"
                :label="row['id']"
              >
                {{ "" }}
              </el-radio>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="strategyName" label="策略名称">
          <template slot-scope="{ row }">
            {{ row["strategyName"] }}
          </template>
        </el-table-column>
        <el-table-column prop="strategyCode" label="策略标识" width="300">
          <template slot-scope="{ row }">
            <div>
              {{ row["strategyCode"] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="getStrategyDetail" size="small"
          >提 交</el-button
        >
      </div>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { dict_ruleType, dict_operator } from "@/enum/dict/index.js";
import {
  getStrategyListAPI,
  getStrategyDetailAPI,
  addAPI,
  editAPI,
  getDetailAPI,
} from "./api";
const columns = [
  {
    label: "",
    prop: "id",
    minWidth: "120",
    customRender(h, row) {
      return (
        <div title={this.findCode(row["strategyCode"]) ? "该策略已被选！" : ""}>
          <el-radio
            disabled={this.findCode(row["strategyCode"])}
            v-model={this.strategyId}
            label={row["id"]}
          >
            {null}
          </el-radio>
        </div>
      );
    },
  },
  {
    label: "策略名称",
    prop: "strategyName",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["strategyName"]}</p>;
    },
  },
  {
    label: "策略标识",
    prop: "strategyCode",
    minWidth: "120",
    fixed: "right",
    customRender(h, row) {
      return <p>{row["strategyCode"]}</p>;
    },
  },
];
export default {
  name: "AddOrEdit",

  data() {
    var checkCtrategy = (rule, value, callback) => {
      if (value.length <= 0) {
        callback(new Error("规则策略不能为空"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        ruleName: [
          { required: true, message: "该项不能为空", trigger: "blur" },
        ],
        ruleType: [
          { required: true, message: "该项不能为空", trigger: "blur" },
        ],
        strategyConfig: [
          { validator: checkCtrategy, trigger: "blur" },
          { required: true },
        ],
      },
      dialogVisible: false,
      formData: {
        ruleName: "",
        ruleType: "",
        strategyConfig: [
          // {
          //   strategyId: 1,
          //   strategy: {
          //     id: 1,
          //     strategyName: '年龄输入型介于',
          //     strategyType: 1,
          //     elType: 1,
          //     elData: null
          //   },
          //   operator: 'between',
          //   hits: ['16', '45']
          // },
          // {
          //   strategyId: 1,
          //   strategy: {
          //     id: 1,
          //     strategyName: '年龄输入型',
          //     strategyType: 1,
          //     elType: 1,
          //     elData: null
          //   },
          //   operator: 'eq',
          //   hits: ['16']
          // },
          // {
          //   strategyId: 2,
          //   strategy: {
          //     id: 1,
          //     strategyName: '枚举型',
          //     strategyType: 1,
          //     elType: 2,
          //     elData: [
          //       { label: '测试1', value: '1' },
          //       { label: '测试2', value: '2' }
          //     ]
          //   },
          //   operator: 'in',
          //   hits: []
          // },
          // {
          //   strategyId: 2,
          //   strategy: {
          //     id: 1,
          //     strategyName: '用户黑名单',
          //     strategyType: 1,
          //     elType: 3,
          //     elData: [
          //       { label: '测试1', value: '1' },
          //       { label: '测试2', value: '2' }
          //     ]
          //   },
          //   operator: 'in',
          //   hits: null
          // }
        ],
      },
      dict_ruleType,
      dict_operator,
      searchData: {
        strategyType: "1",
      },
      columns,

      list: [],
      strategyId: "",
      currentIndex: -1,
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
    "searchData.strategyType": {
      handler(newV, oldV) {
        this.getList();
      },
    },
  },
  mounted() {
    // this.getList()
    if (this.$route.params.id) this.getDetail();
  },

  methods: {
    handleRemove(i) {
      this.formData.strategyConfig.splice(i, 1);
    },
    // 显示策略列表
    showList() {
      this.strategyId = "";

      this.getList();
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    // 策略列表
    async getList() {
      const { strategyType } = this.searchData;
      const params = { strategyType };
      const res = await getStrategyListAPI(params);
      this.list = res || [];
    },
    // 策略详情
    async getStrategyDetail() {
      const { elData, strategyCode, strategyName, elType, strategyType } =
        await getStrategyDetailAPI(this.strategyId);
      let obj = {
        strategy: {
          strategyName: strategyName,
          strategyType: strategyType,
          elType: elType,
          elData: elData || null,
        },
        strategyCode,
        operator: elType == 1 ? "" : "in",
        hits: elType == 3 ? null : [],
      };
      this.formData.strategyConfig.push(obj);
      this.dialogVisible = false;
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success("编辑成功");
              this.$router.back();
            });
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success("添加成功");
              this.$router.back();
            });
          }
        }
      });
    },
    // 详情
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id);
    },
    // 选择前置规则时重置
    handleChangeRuleType(val) {
      if (val == 1) {
        this.formData.strategyConfig = [];
      }
    },
    changeIndex(i) {
      this.currentIndex = i;
    },
    findCode(code) {
      return this.formData.strategyConfig.some(
        (item) => item.strategyCode == code,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .el-form {
    .el-form-item__content {
      line-height: 18px;
    }
  }
  .content {
    min-width: 750px;
    max-width: 900px;
    background: #fafafa;
    border-radius: 7px;
    .row {
      display: flex;
      align-items: center;
      padding: 7px 10px;
      justify-content: space-between;
      border-bottom: 1px solid #ebeef5;
      &:last-child {
        border: none;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
      &:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      .col {
        &:first-child {
          width: 200px;
          padding-right: 10px;
          font-weight: 600;
          // text-align: right;
        }
        &:nth-child(2) {
          flex: 1;
          display: flex;
          align-items: center;
          .eq {
            display: flex;
            align-items: center;
          }
          .el-checkbox {
            margin-right: 15px;
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
        }
        &:last-child {
          width: 75px;
          padding-left: 10px;
        }
      }
      .black {
        justify-content: center;
      }
    }
    .active {
      background-color: #ebeef5;
    }
  }
  .add {
    width: 900px;
    text-align: center;
    margin-top: 20px;
  }
  .empty {
    width: 900px;
    display: flex;
    justify-content: center;
    .el-empty {
      padding: 0;
    }
  }
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
    .el-radio__inner {
      transform: scale(1.3);
    }
  }
  .el-table {
    // max-height: 400px;
    overflow-y: scroll;
    .el-radio__label {
      display: none;
    }
    &::before {
      display: none;
    }
  }

  .el-table--border {
    border-bottom: 1px solid #fff !important;
  }
  .el-table__cell {
    padding: 8px 0;
  }
}
</style>
